@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --blue-rgb: 237 245 255;
  --green-rgb: 125 161 35;
}

body {
  font-family: "Work Sans", sans-serif;
}

body:has(.card[data-color="blue"]:hover) {
  background-color: rgb(var(--blue-rgb) / 25%);
}

body:has(.card[data-color="green"]:hover) {
  background-color: rgb(var(--green-rgb) / 25%);
}

@layer utilities {
  .max-container {
    @apply max-w-5xl mx-auto sm:p-16 pb-8 !pt-[126px] px-8 min-h-[95vh];
  }

  .section-container {
    @apply max-w-5xl mx-auto sm:p-16 pb-12 !pt-[126px] px-8 min-h-[85vh];
  }

  .head-text {
    @apply sm:text-5xl text-3xl font-semibold sm:leading-snug font-poppins;
  }

  .subhead-text {
    @apply font-semibold sm:text-3xl text-xl relative font-poppins;
  }

  .blue-gradient_text {
    @apply bg-gradient-to-r from-[#00c6ff] to-[#0072ff] bg-clip-text text-transparent;
  }

  .input {
    @apply bg-white border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-2.5 font-normal;
  }

  .textarea {
    @apply block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-200 focus:ring-blue-500 focus:border-blue-500 mt-2.5 font-normal;
  }

  .btn {
    @apply text-white bg-gradient-to-r from-[#00c6ff] to-[#0072ff] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center;
  }

  .header {
    @apply flex justify-between items-center sm:px-16 px-8 py-4 max-w-5xl mx-auto absolute top-0 bg-transparent z-10 right-0 left-0;
  }

  .footer {
    @apply max-w-5xl mx-auto sm:px-16 pb-6 px-8 flex flex-col gap-7;
  }

  .footer-container {
    @apply flex flex-wrap gap-7 items-center justify-between;
  }

  .info-box {
    @apply mx-5 relative flex text-white flex-col gap-3 max-w-2xl neo-brutalism-blue pt-4 pb-12 px-8;
  }

  .neo-btn {
    @apply py-3 px-6 rounded-lg text-blue-500 text-center font-semibold sm:w-1/2 w-[90%] -bottom-5 absolute mx-auto right-0 left-0 flex justify-center items-center gap-3;
  }

  .cta {
    @apply w-full flex items-center md:flex-row flex-col sm:mt-16 mt-8 gap-7;
  }

  .cta-text {
    @apply font-extrabold flex-1 text-3xl max-md:text-center;
    font-family: "Merriweather Sans", sans-serif;
  }
}

.glassmorphism {
  background: rgba(8, 116, 239, 0.07);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.logo {
  border-radius: 8.889px;
  background: #fff;
  box-shadow: 0px 10px 35px -4px rgba(67, 83, 255, 0.15),
    0px 1.5px 4px -1px rgba(67, 83, 255, 0.2);
}

.block-container {
  position: relative;
  transition: 250ms;
  perspective: 500px;
}

.block-container .btn-back {
  position: absolute;
  inset: 0;
  z-index: -1;
  width: inherit;
  height: inherit;

  transform-style: preserve-3d;
  transform-origin: bottom right;

  will-change: transform;
  box-shadow: 16px 0 40px #e4e4e4;
}

.block-container .btn-back-red {
  background: linear-gradient(135deg, #ff4b1f -20%, #ff9068 120%);
}

.block-container .btn-back-green {
  background: linear-gradient(135deg, #adfda2 -20%, #11d3f3 120%);
}

.block-container .btn-back-yellow {
  background: linear-gradient(135deg, #f7971e -20%, #ffd200 120%);
}

.block-container .btn-back-blue {
  background: linear-gradient(135deg, #0061ff -20%, #60efff 120%);
}

.block-container .btn-back-orange {
  background: linear-gradient(135deg, #ff0f7b -20%, #f89b29 120%);
}

.block-container .btn-back-pink {
  background: linear-gradient(135deg, #e81cff -20%, #40c9ff 120%);
}

.block-container .btn-back-black {
  background: linear-gradient(135deg, #0a1647 -20%, #e4e7e4 120%);
}

.block-container .btn-front {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: inherit;
  height: inherit;
}

.block-container:hover > .btn-back,
.block-container:hover > .btn-front {
  transform: translateZ(80px) translateY(-5px);
  transition: 250ms;
  transform-style: preserve-3d;
  transform-origin: top left;
  overflow: hidden;
}

/* Neo Brutalism */
.neo-brutalism-blue {
  background: #2b77e7;
  position: relative;
  border-radius: 10px;
  border: #2b77e7;
  box-shadow: 0.6vmin 0.6vmin #336cc1, 1vmin 1vmin #0092db, 1vmin 1vmin #0092db,
    0.65vmin 1vmin #0092db, 1vmin 0.65vmin #0092db;
}

.neo-brutalism-white {
  background: #fff;
  border: #fff;
  box-shadow: 0.6vmin 0.6vmin #fff, 1vmin 1vmin #d2e4ff, 1vmin 1vmin #d2e4ff,
    0.65vmin 1vmin #d2e4ff, 1vmin 0.65vmin #d2e4ff;
}

.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #00c6ff, #0072ff);
  z-index: -1;
  border-radius: inherit; /* Ensures the border radius matches the element if any */
  padding: 2px; /* Adjust this value to change the border thickness */
}

/* Base styles for the dropdown menu */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Start right after the header ends */
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  opacity: 0; /* Start fully transparent */
  visibility: hidden; /* Start hidden */
  transform: translateY(
    -20px
  ); /* Start slightly above the final position for a slide effect */
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s; /* Smooth transition for opacity and transform */
}

/* Class to add when the menu is open to make it visible */
.dropdown-menu-visible {
  opacity: 1; /* Fully visible */
  visibility: visible; /* Make it visible */
  transform: translateY(0); /* Slide to final position */
}
